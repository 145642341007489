import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import DragAndDropFileUpload from "../../../../elements/file-upload/DragAndDropFileUpload";
import { importEAMDataCSV } from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";

export default NiceModal.create(({ assetId, period, refetch }) => {
  const modal = useModal();
  const [errors, setErrors] = useState([]);
  const [attachment, setAttachment] = useState([]);

  const errorCodeDesc = {
    "file-invalid-type": Labels.Journals.fileTypeError,
    "file-too-large": Labels.CommonModals.attachmentSizeError
  };

  const resetErrors = () => {
    setErrors([]);
  };

  const uploadOptions = {
    accept: ".csv",
    maxFiles: 1,
    minSize: 0,
    maxSize: 10485760
  };

  const onError = (errorList) => {
    const errors = {};
    errorList.forEach((errorObj) => {
      errorObj.errors.forEach((error) => {
        errors[error.code] = {
          errorMessage: errorCodeDesc[error.code] || error.message
        };
      });
    });
    setErrors([...Object.values(errors)]);
  };

  const onFilesSelection = (files = []) => {
    setAttachment(files);
  };

  const uploadMutation = useMutation(() => {
    const formData = new FormData();
    formData.append("eamDataFile", attachment[0]);
    formData.append("period", period);
    httpRequest({
      method: "post",
      url: importEAMDataCSV(assetId),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then((response) => {
        refetch();
        modal.remove();
      })
      .catch((error) => {
        if (typeof error == "string") {
          setErrors([{ errorMessage: error }]);
        } else {
          setErrors(error.data.errors);
        }
      });
  });

  const importData = () => {
    uploadMutation.mutate();
  };

  return (
    <Modal
      isOpen={true}
      keyboard={false}
      backdrop="static"
      centered
      fade={false}
    >
      <ModalHeader className="pb-2" toggle={modal.remove}>
        <h2 className="color-dark modal-title">
          {Labels.Organisations.importData.importData}
        </h2>
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <div>
          {errors.length ? (
            <div className="">
              <Alert color="danger" toggle={resetErrors}>
                <ul className="error-list">
                  {errors.map((error, index) => {
                    return <li key={index}>{error.errorMessage}</li>;
                  })}
                </ul>
              </Alert>
            </div>
          ) : null}
          <DragAndDropFileUpload
            onFileSelection={onFilesSelection}
            onError={onError}
            label={Labels.CommonModals.uploadFileLabel}
            button={true}
            uploadConfig={uploadOptions}
          />
        </div>
        <div className="clearfix">&nbsp;</div>
        <div className="pt-2 justify-content-center d-flex">
          <Button
            color="primary"
            disabled={attachment.length <= 0}
            onClick={importData}
          >
            {Labels.CommonModals.import}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
});
