import NiceModal from "@ebay/nice-modal-react";
import SalesInvoiceDownloadSpecialInstructions from "../../views/Assets/PayRuns/SalesInvoices/SalesInvoiceDownloadSpecialInstructions";
import SalesInvoiceSendStatementModal from "../../views/Assets/PayRuns/SalesInvoices/SalesInvoiceSendStatementModal";
import CopyActionModal from "./CopyActionModal";
import DocsViewerModal from "./DocsViewerModal";
import ChecksDataModal from "./ChecksDataModal";
import PrepaymentsDrillDownDataModal from "../../views/Assets/Reports/BalanceSheet/PrepaymentsDrillDownDataModal";
import AddSupplierForm from "../../views/Assets/PayRuns/PurchaseOrders/AddSupplierForm";
import SendRemittanceEmailModal from "../../views/Assets/PayRuns/remittance/SendRemittanceEmailModal";
import ImportProjectInvoicesModal from "../../views/Assets/Insights/Projects/Invoices/modals/ImportProjectInvoicesModal";
import ImportProjectInvoicesUsingSoftwareModal from "../../views/Assets/Insights/Projects/Invoices/modals/ImportProjectInvoicesUsingSoftwareModal";
import ProjectInvoiceActionModal from "../../views/Assets/Insights/Projects/Invoices/modals/ProjectInvoiceActionModal";
import UserForm from "../../views/UserManagement/Users/UserForm";
import PurchaseOrderSendStatementModal from "../../views/Assets/PayRuns/PurchaseOrders/PurchaseOrderSendStatementModal";
import PurchaseOrderOverBudgetWarningModal from "../../views/Assets/PayRuns/PurchaseOrders/PurchaseOrderOverBudgetWarningModal";
import PurchaseOrderBudgetModal from "../../views/Assets/PayRuns/PurchaseOrders/PurchaseOrderBudgetModal";
import AssignForm from "../../views/Assets/Accounting/FixedAssetRegister/AssetClass/AssignForm";
import CreateAssetClassForm from "../../views/Assets/Accounting/FixedAssetRegister/AssetClass/CreateAssetClassForm";
import RegisterFixedAssetForm from "../../views/Assets/Accounting/FixedAssetRegister/Register/RegisterFixedAssetForm";
import VoidConfirmDialog from "../../views/Assets/Accounting/FixedAssetRegister/Register/VoidConfirmDialog";
import AssetBudgetModal from "../../views/Assets/Accounting/Budgets/AssetBudgetModal";
import ImportAssetBudgetModal from "../../views/Assets/Accounting/Budgets/ImportAssetBudgetModal";
import ImportModal from "../../views/Assets/Accounting/Journals/Manual/ImportModal";
import JournalActionsModal from "../../views/Assets/Accounting/Journals/JournalActionsModal";
import SyncBankStatementsModal from "../../views/Assets/Accounting/BankAccounts/SyncBankStatementsModal";
import BankDetailsEditForm from "../../views/Assets/Settings/ImportData/BankAccountDetails/BankDetailsEditForm";
import TransactionVoidActionCommentDialog from "../../views/Assets/Accounting/BankAccounts/Transactions/TransactionDetails/TransactionVoidActionCommentDialog";
import TransactionReturnToDraftActionCommentDialog from "../../views/Assets/Accounting/BankAccounts/Transactions/TransactionDetails/TransactionReturnToDraftActionCommentDialog";
import TransactionUnreconcileActionCommentDialog from "../../views/Assets/Accounting/BankAccounts/Transactions/TransactionUnreconcileActionCommentDialog";
import CategoryAssignToAssetModal from "../../views/Assets/Tagging/Budgets/BudgetLineItem/AssignCategoryToAssetModal";
import AddNewCategoryModal from "../../views/Assets/Tagging/AddNewCategoryModal";
import EditAttributeModal from "../../views/Assets/Tagging/EditAttributeModal";
import PayRunDownloadModal from "../../views/Assets/PayRuns/PayRuns/PayRunDownloadModal";
import RegisterCardDetailsModal from "../../views/Assets/Accounting/FixedAssetRegister/Register/RegisterCardDetailsModal";
import ImportTaggingInvoicesModal from "../../views/Assets/Tagging/Invoices/modals/ImportTaggingInvoicesModal";
import ImportTaggingInvoicesUsingSoftwareModal from "../../views/Assets/Tagging/Invoices/modals/ImportTaggingInvoicesUsingSoftwareModal";
import TaggingInvoiceActionModal from "../../views/Assets/Tagging/Invoices/modals/TaggingInvoiceActionModal";
import CreateTemplateModal from "../../views/Assets/PayRuns/Utilisations/Templates/CreateTemplateModal";
import TaskArchiveReasonModal from "../../views/Assets/Compliance/TaskArchiveReasonModal";
import TaskImportModal from "../../views/Assets/Compliance/Tasks/TaskImportModal";
import PreviewAndCreateUtilisationRequest from "../../views/Assets/PayRuns/Utilisations/UtilisationRequests/PreviewAndCreateUtilisationRequest";
import VarianceBudgetAmountDrillDownModal from "../../views/Assets/Reports/IncomeStatement/default/VarianceBudgetAmountDrillDownModal";
import VarianceActualAmountDrillDownModal from "../../views/Assets/Reports/IncomeStatement/default/VarianceActualAmountDrillDownModal";
import UploadSignedUtilisationRequestModal from "../../views/Assets/PayRuns/Utilisations/UtilisationRequests/UploadSignedUtilisationRequestModal";
import UnrealisedAmountDrilldownModal from "../../views/Assets/Reports/IncomeStatement/default/UnrealisedAmountDrilldownModal";
import FirewallErrorModal from "../../elements/modals/FirewallErrorModal";
import AssetSettingsModal from "../../elements/list/AssetTable/AssetSettingsModal";
import PurchaseOrderAccrualScheduleModal from "../../views/Assets/PayRuns/PurchaseOrders/PurchaseOrderAccrualScheduleModal";
import CreateFAMUser from "../../views/UserManagement/Users/CreateFAMUser";
import PurchaseOrderImportModal from "../../views/Assets/PayRuns/PurchaseOrders/PurchaseOrderImportModal";
import ActualSpendDataHistoryModal from "../../views/Assets/Reports/ExportData/ActualSpendDataHistoryModal";
import FXRevaluationHistoryModal from "../../views/Assets/Settings/ImportData/Asset/FXRevaluationHistoryModal";
import FxRatesHistoryModal from "../../views/Assets/FXRates/FXTable/FxRatesHistoryModal";
import ImportRevenueAutomationDataPointModal from "../../views/Assets/Insights/RevenueAutomation/Setup/ImportRevenueAutomationDataPointModal";
import ImportLAFModal from "../../views/Assets/Insights/RevenueAutomation/Setup/LossAdjustmentFactor/ImportLAFModal";
import ImportCPIModal from "../../views/Assets/Insights/RevenueAutomation/Setup/ConsumerPriceIndex/ImportCPIModal";
import AddCPIModal from "../../views/Assets/Insights/RevenueAutomation/Setup/ConsumerPriceIndex/AddCPIModal";
import ReleaseDataConfirmModal from "../../views/Assets/Settings/ImportData/Asset/ReleaseDataConfirmModal";
import CreateTaskModal from "../../views/Assets/Reports/Checks/Risks/CreateTaskModal";
import ExpandedCashBridgeModal from "../../views/Portfolios/Dashboard/ExpandedCashBridgeModal";
import ImportReportingMasterDataModal from "../../views/Assets/Settings/Organisations/Settings/ReportingMasterData/ImportReportingMasterDataModal";
import ImportAssetReportingMasterDataModal from "../../views/Assets/Settings/ImportData/Asset/ImportAssetReportingMasterDataModal";
import AssetDataRejectionModal from "../../views/Assets/Accounting/ImportData/AssetDataRejectionModal";
import ImportAssetDataModal from "../../views/Assets/Accounting/ImportData/ImportAssetDataModal";

// https://github.com/eBay/nice-modal-react#use-the-modal-by-id
// Place to register all the modals

export const modalNames = {
  copyDraftAction: "copy-draft-action",
  enterSalesInvoiceDownloadComments: "enter-sales-invoice-download-comments",
  viewPdf: "view-pdf",
  checksDataModal: "checks-data-modal",
  prepaymentsDrillDownData: "prepayments-drilldown-data",
  sendRemittance: "send-remittance",
  importProjectInvoices: "import-project-invoices",
  addSupplierForm: "addSupplierForm",
  importProjectInvoicesUsingSoftwareModal:
    "import-project-invoices-using-software-modal",
  projectInvoiceActionModal: "project-invoice-action-modal",
  taggingInvoiceActionModal: "tagging-invoice-action-modal",
  importTaggingInvoices: "import-tagging-invoices",
  importTaggingInvoicesUsingSoftwareModal:
    "import-tagging-invoices-using-software-modal",
  createOrEditUser: "create-user",
  sendPurchaseOrderEmailModal: "purchase-order-email-modal",
  purchaseOrderOverBudgetWarningModal:
    "purchase-order-over-budget-warning-modal",
  purchaseOrderBudgetModal: "purchase-order-budget-modal",
  sendSalesInvoiceEmailModal: "sales-invoice-email-modal",
  fixedAssetRegisterAssignForm: "fixed-asset-register-assign-form",
  registerCardDetailsModal: "RegisterCardDetailsModal",
  createAssetClassForm: "create-asset-class-form",
  registerFixedAssetForm: "register-fixed-asset-form",
  fixedAssetVoidConfirmDialog: "fixed-asset-void-confirm-dialog",
  assetBudgetModal: "asset-budget-modal",
  importAssetBudgetModal: "import-asset-budget-modal",
  importManualJournalsModal: "import-manual-journals-modal",
  journalActionsModal: "journal-action-modal",
  syncBankStatementsModal: "sync-bank-statement-modal",
  bankDetailsEditForm: "bank-details-edit-form",
  transactionVoidActionCommentDialog: "transaction-void-action-comment-dialog",
  transactionReturnToDraftActionCommentDialog:
    "transaction-return-to-draft-action-comment-dialog",
  transactionUnreconcileActionCommentDialog:
    "transaction-unreconcile-action-comment-dialog",
  categoryAssignToAssets: "categoty-assign-to-assets",
  openAddNewCategoryModal: "openAddNewCategoryModal",
  payRunDownloadModal: "payrun-download-modal",
  openEditAttributeModal: "openEditAttributeModal",
  CreateTemplateModal: "asset-utilisation-template-modal",
  taskArchiveReasonModal: "task-archive-reason-modal",
  taskImportModal: "task-import-modal",
  varianceBudgetAmountDrillDownModal: "variance-budget-amount-drill-down-modal",
  varianceActualAmountDrillDownModal: "variance-actual-amount-drill-down-modal",
  uploadSignedUtilisationRequestModal:
    "upload-signed-utilisation-request-modal",
  previewAndCreateUtilisationRequest: "preview-and-create-utilisation-request",
  unrealisedAmountDrilldownModal: "unrealised-amount-drilldown",
  firewallErrorModal: "firewall-error-modal",
  assetSettingsModal: "asset-settings-modal",
  purchaseOrderAccrualScheduleModal: "purchase-order-accrual-schedule-modal",
  createFAMUser: "create-fam-user",
  purchaseOrderImportModal: "purchase-order-import-modal",
  actualSpendDataHistoryModal: "actual-spend-data-history-modal",
  fxRevaluationHistoryModal: "fx-revaluation-history-modal",
  fxRatesHistoryModal: "fx-rates-history-modal",
  importRevenueAutomationDataPointModal:
    "importRevenueAutomationDataPointModal",
  importLAFModal: "importLAFModal",
  importCPIModal: "importCPIModal",
  addCPIModal: "addCPIModal",
  releaseDataConfirmModal: "release-data-confirm-modal",
  createTaskModal: "create-task-modal",
  importReportingMasterDataModal: "import-reporting-master-data-modal",
  portfolioExpandCashBridgeModal: "portfolio-expand-cash-bridge-modal",
  importAssetReportingMasterDataModal:
    "import-asset-reporting-master-data-modal",
  assetDataRejectionModal: "asset-data-rejection-modal",
  importAssetDataModal: "import-asset-data-modal"
};

NiceModal.register(modalNames.copyDraftAction, CopyActionModal);
NiceModal.register(modalNames.viewPdf, DocsViewerModal);
NiceModal.register(modalNames.checksDataModal, ChecksDataModal);
NiceModal.register(modalNames.sendRemittance, SendRemittanceEmailModal);
NiceModal.register(
  modalNames.enterSalesInvoiceDownloadComments,
  SalesInvoiceDownloadSpecialInstructions
);
NiceModal.register(
  modalNames.prepaymentsDrillDownData,
  PrepaymentsDrillDownDataModal
);
NiceModal.register(modalNames.addSupplierForm, AddSupplierForm);
NiceModal.register(
  modalNames.importProjectInvoices,
  ImportProjectInvoicesModal
);
NiceModal.register(
  modalNames.importProjectInvoicesUsingSoftwareModal,
  ImportProjectInvoicesUsingSoftwareModal
);

NiceModal.register(
  modalNames.projectInvoiceActionModal,
  ProjectInvoiceActionModal
);

NiceModal.register(
  modalNames.importTaggingInvoices,
  ImportTaggingInvoicesModal
);

NiceModal.register(
  modalNames.taggingInvoiceActionModal,
  TaggingInvoiceActionModal
);

NiceModal.register(
  modalNames.importTaggingInvoicesUsingSoftwareModal,
  ImportTaggingInvoicesUsingSoftwareModal
);

NiceModal.register(modalNames.createOrEditUser, UserForm);
NiceModal.register(
  modalNames.sendPurchaseOrderEmailModal,
  PurchaseOrderSendStatementModal
);
NiceModal.register(
  modalNames.purchaseOrderOverBudgetWarningModal,
  PurchaseOrderOverBudgetWarningModal
);
NiceModal.register(
  modalNames.purchaseOrderBudgetModal,
  PurchaseOrderBudgetModal
);
NiceModal.register(
  modalNames.registerCardDetailsModal,
  RegisterCardDetailsModal
);
NiceModal.register(
  modalNames.sendSalesInvoiceEmailModal,
  SalesInvoiceSendStatementModal
);
NiceModal.register(modalNames.fixedAssetRegisterAssignForm, AssignForm);
NiceModal.register(modalNames.createAssetClassForm, CreateAssetClassForm);
NiceModal.register(modalNames.registerFixedAssetForm, RegisterFixedAssetForm);
NiceModal.register(modalNames.fixedAssetVoidConfirmDialog, VoidConfirmDialog);
NiceModal.register(modalNames.assetBudgetModal, AssetBudgetModal);
NiceModal.register(modalNames.importAssetBudgetModal, ImportAssetBudgetModal);
NiceModal.register(modalNames.importManualJournalsModal, ImportModal);
NiceModal.register(modalNames.journalActionsModal, JournalActionsModal);
NiceModal.register(modalNames.syncBankStatementsModal, SyncBankStatementsModal);
NiceModal.register(modalNames.bankDetailsEditForm, BankDetailsEditForm);
NiceModal.register(
  modalNames.transactionVoidActionCommentDialog,
  TransactionVoidActionCommentDialog
);
NiceModal.register(
  modalNames.transactionReturnToDraftActionCommentDialog,
  TransactionReturnToDraftActionCommentDialog
);
NiceModal.register(
  modalNames.transactionUnreconcileActionCommentDialog,
  TransactionUnreconcileActionCommentDialog
);
NiceModal.register(
  modalNames.categoryAssignToAssets,
  CategoryAssignToAssetModal
);
NiceModal.register(modalNames.openAddNewCategoryModal, AddNewCategoryModal);
NiceModal.register(modalNames.openEditAttributeModal, EditAttributeModal);
NiceModal.register(modalNames.payRunDownloadModal, PayRunDownloadModal);
NiceModal.register(modalNames.CreateTemplateModal, CreateTemplateModal);
NiceModal.register(modalNames.taskArchiveReasonModal, TaskArchiveReasonModal);
NiceModal.register(modalNames.taskImportModal, TaskImportModal);
NiceModal.register(
  modalNames.previewAndCreateUtilisationRequest,
  PreviewAndCreateUtilisationRequest
);
NiceModal.register(
  modalNames.varianceBudgetAmountDrillDownModal,
  VarianceBudgetAmountDrillDownModal
);
NiceModal.register(
  modalNames.varianceActualAmountDrillDownModal,
  VarianceActualAmountDrillDownModal
);
NiceModal.register(
  modalNames.uploadSignedUtilisationRequestModal,
  UploadSignedUtilisationRequestModal
);
NiceModal.register(
  modalNames.unrealisedAmountDrilldownModal,
  UnrealisedAmountDrilldownModal
);
NiceModal.register(modalNames.firewallErrorModal, FirewallErrorModal);
NiceModal.register(modalNames.assetSettingsModal, AssetSettingsModal);
NiceModal.register(
  modalNames.purchaseOrderAccrualScheduleModal,
  PurchaseOrderAccrualScheduleModal
);
NiceModal.register(modalNames.createFAMUser, CreateFAMUser);
NiceModal.register(
  modalNames.purchaseOrderImportModal,
  PurchaseOrderImportModal
);
NiceModal.register(
  modalNames.actualSpendDataHistoryModal,
  ActualSpendDataHistoryModal
);
NiceModal.register(
  modalNames.fxRevaluationHistoryModal,
  FXRevaluationHistoryModal
);
NiceModal.register(modalNames.fxRatesHistoryModal, FxRatesHistoryModal);
NiceModal.register(
  modalNames.importRevenueAutomationDataPointModal,
  ImportRevenueAutomationDataPointModal
);
NiceModal.register(modalNames.importLAFModal, ImportLAFModal);
NiceModal.register(modalNames.importCPIModal, ImportCPIModal);
NiceModal.register(modalNames.addCPIModal, AddCPIModal);
NiceModal.register(modalNames.releaseDataConfirmModal, ReleaseDataConfirmModal);
NiceModal.register(modalNames.createTaskModal, CreateTaskModal);
NiceModal.register(
  modalNames.portfolioExpandCashBridgeModal,
  ExpandedCashBridgeModal
);
NiceModal.register(
  modalNames.importReportingMasterDataModal,
  ImportReportingMasterDataModal
);

NiceModal.register(
  modalNames.importAssetReportingMasterDataModal,
  ImportAssetReportingMasterDataModal
);

NiceModal.register(modalNames.assetDataRejectionModal, AssetDataRejectionModal);
NiceModal.register(modalNames.importAssetDataModal, ImportAssetDataModal);
